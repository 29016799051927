import { query, createAsync } from "@solidjs/router";
import { retrieveProductPricing } from "../roma-api/products/";

export const getProductPricing = query(async (sku: string) => {
  return retrieveProductPricing(sku);
}, "product-pricing");


export const useProductPricing = (
  sku: () => string,
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getProductPricing(sku()), options);
