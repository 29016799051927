import { query, createAsync } from "@solidjs/router";
import { _retrieveBulkInventory } from "../roma-api/products";

export const getBulkInventory = query(
  async (skus: string[]) => _retrieveBulkInventory(skus), 
  "bulk-inventory"
);

export const useBulkInventory = (
  skus: () => string[],
  options = { deferStream: false }
) => createAsync(() => getBulkInventory(skus()), options);
