import { query } from "@solidjs/router";
import { retrieveProduct } from "../roma-api/products/";
import { ProductFilters } from "../roma-api/products/types";

export const useProduct = query(
  async (sku: string, filters?: ProductFilters) => {
    return retrieveProduct(sku, filters);
  },
  "products"
);

// TODO; Change useProduct ot getProduct, useProduct should return createAsync, rename retrieveProduct to _getProduct etc....
