import type { ProductPriceResponse } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const retrieveProductPricing = async (
  sku: string
): Promise<ProductPriceResponse> => {


  // await simulateApiError({path: `/products/${sku}/prices`})

  const data = await fetchAPI2<ProductPriceResponse>(`/products/${sku}/prices`);


  return data;
};
