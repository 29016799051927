import type { InventoryByPlant } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _retrieveBulkInventory = async (
  skus: string[]
): Promise<Record<string, InventoryByPlant>> => {
  // TODO TEST - this is used for stacks, has not been tested yet

  // await simulateApiError({path: "/inventory"})

  const data = await fetchAPI2<Record<string, InventoryByPlant>, string[]>(
    "/inventory",
    { method: "POST", body: skus }
  );

  return data;
};
